import Swal from "sweetalert2";

class ConfirmationDialog {
  title = '';
  confirmButtonText = '';
  cancelButtonText = '';

  constructor(title = 'Bạn có chắc chắn muốn thực hiện hành động này?',
              confirmButtonText = 'Xác nhận',
              cancelButtonText = 'Hủy'
  ) {
    this.title = title;
    this.confirmButtonText = confirmButtonText;
    this.cancelButtonText = cancelButtonText;
  }

  show() {
    return Swal.fire({
      title: this.title,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: this.confirmButtonText,
      cancelButtonText: this.cancelButtonText,
      reverseButtons: true,
    });
  }

}

export default ConfirmationDialog;